.Toastify__toast-container--top-right {
    top: 8em !important;
    right: 4.5em !important;
}

.css-1xmfod5{
    position: fixed !important;
    right: 4.5em !important;
    top: 12em !important;
}



@media only screen and (max-width: 1440px) {
    .css-1xmfod5{
        position: fixed !important;
        right: 4.3em !important;
        top: 12em !important;
    }

    .Toastify__toast-container--top-right {
        top: 18em !important;
        right: 4.5em !important;
    }
}

@media only screen and (max-width: 1024px) {
    .css-1xmfod5{
        position: fixed !important;
        right: 4em !important;
        top: 15em !important;
    }

    .Toastify__toast-container--top-right {
        top: 20em !important;
        right: 4em !important;
    }
}

@media only screen and (max-width: 768px) {
    
    .css-1xmfod5{
        position: fixed !important;
        right: 12em !important;
        top: 15em !important;
    }

    .Toastify__toast-container--top-right {
        top: 8em !important;
        right: 100% !important;
    }
}

@media only screen and (max-width: 425px) {
    
    .css-1xmfod5{
        position: fixed !important;
        right: 3em !important;
        top: 10em !important;
    }

    .Toastify__toast-container--top-right {
        top: 8em !important;
        right: 4.5em !important;
    }
}

@media only screen and (max-width: 375px) {

    .css-1xmfod5 {
        position: fixed !important;
        right: 2em !important;
        top: 10em !important;
    }
}

@media only screen and (max-width: 320px) {

    .css-1xmfod5 {
        position: fixed !important;
        right: 0em !important;
        top: 10em !important;
    }
}

