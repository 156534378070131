.icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTI0NyIgaGVpZ2h0PSIxMjQ3IiB2aWV3Qm94PSIwIDAgMTI0NyAxMjQ3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMzQ2LjY2NiA5NTIuNzA4QzI4MS4xODMgOTUyLjcwOCAyMTguMzgxIDkyNi42OTYgMTcyLjA3NyA4ODAuMzkyQzEyNS43NzMgODM0LjA4OCA5OS43NiA3NzEuMjg0IDk5Ljc2IDcwNS44MDJDOTkuNzYgNjQwLjMyIDEyNS43NzMgNTc3LjUxNiAxNzIuMDc3IDUzMS4yMTJDMjE4LjM4MSA0ODQuOTA5IDI4MS4xODMgNDU4Ljg5NiAzNDYuNjY2IDQ1OC44OTZWNzA1LjgwMlY5NTIuNzA4WiIgZmlsbD0iI0REODMxRCIvPgo8cGF0aCBkPSJNOTAwLjMzNCA5NTIuNzA4Qzk2NS44MTYgOTUyLjcwOCAxMDI4LjYyIDkyNi42OTYgMTA3NC45MiA4ODAuMzkyQzExMjEuMjMgODM0LjA4OCAxMTQ3LjI0IDc3MS4yODQgMTE0Ny4yNCA3MDUuODAyQzExNDcuMjQgNjQwLjMyIDExMjEuMjMgNTc3LjUxNiAxMDc0LjkyIDUzMS4yMTJDMTAyOC42MiA0ODQuOTA5IDk2NS44MTYgNDU4Ljg5NiA5MDAuMzM0IDQ1OC44OTZWNzA1LjgwMlY5NTIuNzA4WiIgZmlsbD0iI0REODMxRCIvPgo8cGF0aCBkPSJNMjU5LjM3NiA0OTguOEMyNTkuMzc2IDE3LjI4OTYgOTkyLjYxMiAtMy44NzQ1NCA5OTIuNjEyIDQ5OC44IiBzdHJva2U9IiNCRDcwMTkiIHN0cm9rZS13aWR0aD0iMTgiLz4KPHBhdGggZD0iTTYxOC41MTIgMTEyOS43OEw5NTcuMDEzIDEwNTAuNFY5MDIuODI4IiBzdHJva2U9IiNCRDcwMTkiIHN0cm9rZS13aWR0aD0iMTUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8cGF0aCBkPSJNNzcxLjk4OCAxMDI4Ljc4TDU5OC41ODkgMTA2MS4yOEM1NjUuNTE1IDEwNjcuNDcgNTQzLjcyOSAxMDk5LjMxIDU0OS45MjggMTEzMi4zOEM1NTYuMTI2IDExNjUuNDYgNTg3Ljk2MyAxMTg3LjI0IDYyMS4wMzYgMTE4MS4wNUw3OTQuNDM2IDExNDguNTVDODI3LjUwOSAxMTQyLjM1IDg0OS4yOTUgMTExMC41MSA4NDMuMDk3IDEwNzcuNDRDODM2Ljg5OCAxMDQ0LjM2IDgwNS4wNjEgMTAyMi41OCA3NzEuOTg4IDEwMjguNzhaIiBmaWxsPSIjREQ4MzFEIi8+Cjwvc3ZnPgo=);
  /* background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTI0NyIgaGVpZ2h0PSIxMjQ3IiB2aWV3Qm94PSIwIDAgMTI0NyAxMjQ3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMzQ2LjY2NiA5NTIuNzA4QzI4MS4xODMgOTUyLjcwOCAyMTguMzgxIDkyNi42OTYgMTcyLjA3NyA4ODAuMzkyQzEyNS43NzMgODM0LjA4OCA5OS43NiA3NzEuMjg0IDk5Ljc2IDcwNS44MDJDOTkuNzYgNjQwLjMyIDEyNS43NzMgNTc3LjUxNiAxNzIuMDc3IDUzMS4yMTJDMjE4LjM4MSA0ODQuOTA5IDI4MS4xODMgNDU4Ljg5NiAzNDYuNjY2IDQ1OC44OTZWNzA1LjgwMlY5NTIuNzA4WiIgZmlsbD0iI0VFNEQzMCIvPgo8cGF0aCBkPSJNOTAwLjMzNCA5NTIuNzA4Qzk2NS44MTYgOTUyLjcwOCAxMDI4LjYyIDkyNi42OTYgMTA3NC45MiA4ODAuMzkyQzExMjEuMjMgODM0LjA4OCAxMTQ3LjI0IDc3MS4yODQgMTE0Ny4yNCA3MDUuODAyQzExNDcuMjQgNjQwLjMyIDExMjEuMjMgNTc3LjUxNiAxMDc0LjkyIDUzMS4yMTJDMTAyOC42MiA0ODQuOTA5IDk2NS44MTYgNDU4Ljg5NiA5MDAuMzM0IDQ1OC44OTZWNzA1LjgwMlY5NTIuNzA4WiIgZmlsbD0iI0VFNEQzMCIvPgo8cGF0aCBkPSJNMjU5LjM3NiA0OTguOEMyNTkuMzc2IDE3LjI4OTYgOTkyLjYxMiAtMy44NzQ1NSA5OTIuNjEyIDQ5OC44IiBzdHJva2U9IiNDRjNCMjEiIHN0cm9rZS13aWR0aD0iMTgiLz4KPHBhdGggZD0iTTYxOC41MTIgMTEyOS43OEw5NTcuMDEzIDEwNTAuNFY5MDIuODI4IiBzdHJva2U9IiNDRjNCMjEiIHN0cm9rZS13aWR0aD0iMTUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8cGF0aCBkPSJNNzcxLjk4OCAxMDI4Ljc4TDU5OC41ODkgMTA2MS4yOEM1NjUuNTE1IDEwNjcuNDcgNTQzLjcyOSAxMDk5LjMxIDU0OS45MjggMTEzMi4zOEM1NTYuMTI2IDExNjUuNDYgNTg3Ljk2MyAxMTg3LjI0IDYyMS4wMzYgMTE4MS4wNUw3OTQuNDM2IDExNDguNTVDODI3LjUwOSAxMTQyLjM1IDg0OS4yOTUgMTExMC41MSA4NDMuMDk3IDEwNzcuNDRDODM2Ljg5OCAxMDQ0LjM2IDgwNS4wNjEgMTAyMi41OCA3NzEuOTg4IDEwMjguNzhaIiBmaWxsPSIjRUU0RDMwIi8+Cjwvc3ZnPgo=); */
  /* background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAwIiBoZWlnaHQ9IjUwMCIgdmlld0JveD0iMCAwIDUwMCA1MDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xNDMuMDM3IDM2MS43OUMxMTcuODMyIDM2MS43OSA5My42NTg3IDM1MS43NzggNzUuODM1NyAzMzMuOTU1QzU4LjAxMjggMzE2LjEzMiA0OCAyOTEuOTU5IDQ4IDI2Ni43NTNDNDggMjQxLjU0OCA1OC4wMTI4IDIxNy4zNzUgNzUuODM1NyAxOTkuNTUyQzkzLjY1ODYgMTgxLjcyOSAxMTcuODMyIDE3MS43MTYgMTQzLjAzNyAxNzEuNzE2TDE0My4wMzcgMjY2Ljc1M0wxNDMuMDM3IDM2MS43OVoiIGZpbGw9IiNGRjk4MDAiLz4KPHBhdGggZD0iTTM1Ni4xNTEgMzYxLjc5QzM4MS4zNTYgMzYxLjc5IDQwNS41MjkgMzUxLjc3OCA0MjMuMzUyIDMzMy45NTVDNDQxLjE3NSAzMTYuMTMyIDQ1MS4xODggMjkxLjk1OSA0NTEuMTg4IDI2Ni43NTNDNDUxLjE4OCAyNDEuNTQ4IDQ0MS4xNzUgMjE3LjM3NSA0MjMuMzUyIDE5OS41NTJDNDA1LjUyOSAxODEuNzI5IDM4MS4zNTYgMTcxLjcxNiAzNTYuMTUxIDE3MS43MTZMMzU2LjE1MSAyNjYuNzUzTDM1Ni4xNTEgMzYxLjc5WiIgZmlsbD0iI0ZGOTgwMCIvPgo8cGF0aCBkPSJNMTA4IDE4Ny4wNzZDMTA4IDEuNzM2NTIgMzkwLjIzMSAtNi40MDk4NCAzOTAuMjMxIDE4Ny4wNzYiIHN0cm9rZT0iI0ZGOTgwMCIgc3Ryb2tlLXdpZHRoPSIzMCIvPgo8cGF0aCBkPSJNMjQ3LjY3NCA0MjkuOTQ4TDM3Ny45NjYgMzk5LjM5NVYzNDIuNTkxIiBzdHJva2U9IiNGRjk4MDAiIHN0cm9rZS13aWR0aD0iMzAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8cmVjdCB4PSIyMTYuOTU1IiB5PSI0MDcuODk5IiB3aWR0aD0iMTE0LjgwOSIgaGVpZ2h0PSI0Ni45MDMzIiByeD0iMjMuNDUxNyIgdHJhbnNmb3JtPSJyb3RhdGUoLTEwLjYxNTQgMjE2Ljk1NSA0MDcuODk5KSIgZmlsbD0iI0ZGOTgwMCIvPgo8L3N2Zz4K); */
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  display: inline-block !important;
  width: 100%;
  height: 100%;
}
